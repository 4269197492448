// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Layout from "../components/Layout"
import RichText from "../components/RichText"
import { RelatedProjects, RelatedJournals } from "../components/Related"

const JournalTemplate = ({ data }) => {

	// Content
	const { title, content, relatedJournals, relatedProjects, shareImage, metaDescription } = data.contentfulJournal

  return (
    <Layout 
			headerTitle="Back to journal" 
			headerLink="/journal"
			hideLogo 
			theme={{
				background: 'var(--green-bg)',
				highlight: 'var(--green)'
			}}
			title={`${title} | AFK`}
			shareImage={shareImage}
			description={metaDescription}
		>
			<div className="journal-view header-offset">
				
				<article className="p4 outerx4 has-indents">
					<RichText content={content} />
				</article>

				{/* Related Journals */}
				{relatedJournals && (
					<RelatedJournals journals={relatedJournals} />
				)}

				{/* Related projects */}
				{relatedProjects && (
					<RelatedProjects projects={relatedProjects} />
				)}
			</div>
    </Layout>
  )
}

JournalTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default JournalTemplate

export const JournalTemplatePageQuery = graphql`
  query JournalTemplateQuery($slug: String!) {
    contentfulJournal(slug: {eq: $slug}) {
			title
			shareImage: thumbnail {
				gatsbyImageData(
					layout: CONSTRAINED
					width: 640
				)
			}
			metaDescription {
				text: metaDescription
			}
			content {
				raw
				references {
					... on Node {
						... on ContentfulGallery {
							...ContentfulGalleryFragment
						}
						... on ContentfulAsset {
							__typename # isRequired
							contentful_id # isRequired
							title
							description
							file {
								contentType
								url
							}
							image: gatsbyImageData(
								layout: FULL_WIDTH
								# formats: [JPG]
								quality: 80
							)
						}
						... on ContentfulQuote {
							...ContentfulQuoteFragment
						}
						... on ContentfulVideo {
							...ContentfulVideoFragment
						}
					}
				}
			}
			relatedJournals {
				...JournalLinkFragment
			}
			relatedProjects {
				...ProjectThumbnailFragment
			}
    }
  }
`

